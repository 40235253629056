::-webkit-scrollbar {
    width: 6px;
}

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

 ::-webkit-scrollbar-thumb {
    background: #888;
}

.p-tree-filter-container {
    position: relative;
    searchicon {
        .p-tree-filter-icon {
            position: absolute;
            top: 11px;
            left: 10px;
            font-size: 13px;
            right: 0;
            margin: 0;
        }
    }
    input {
        border-radius: 40px;
        padding-left: 30px;
        font-size: 13px;
    }
}

// loader
p-progressspinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.mainWrapper {
    padding: 0px 0px 0 10px;
    display: flex;
    gap: 10px;
    min-height: calc(100vh - 90px);
    background-color: $primary-color;
    height: 100%;
    overflow: auto;
    app-side-nav {
        max-width: 220px;
        display: flex;
        width: 100%;
        overflow: auto;
        flex-direction: column;
        grid-gap: 10px;
        padding-top: 10px;
        height: 100vh;
        position: sticky;
        top: 0;
        z-index: 999;
        .logo {
            max-width: 220px;
            border: 2px solid $sky-blue-color;
            border-radius: 10px;
            padding: 3px 22px 4px;
            display: flex;
        }
        .sideMenu {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 2px solid $light-blue-color-1;
            border-radius: 10px;
            padding: 10px;
            height: calc(100vh - 90px);
            overflow: auto;
            li {
                display: flex;
                grid-gap: 10px;
                flex-direction: column;
                line-height: 24px;
                font-weight: 500;
                width: 100%;
                position: relative;
                .sidebarMenu {
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;
                    padding: 10px;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    p {
                        margin: 0;
                        flex: 1;
                    }
                    &.active {
                        background-color: $sky-light-blue;
                        &:before {
                            position: absolute;
                            content: "";
                            left: 0;
                            border: 2px solid $blue-color;
                            height: 100%;
                        }
                    }
                    .pi-angle-down {
                        &.expand {
                            transform: rotate(-180deg);
                            -webkit-transform: rotate(-180deg);
                            -moz-transform: rotate(-180deg);
                        }
                    }
                }
                ul {
                    li {
                        flex-direction: row;
                        padding-left: 20px;
                        line-height: 30px;
                        align-items: center;
                        padding-bottom: 15px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .contentWrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: auto;
        height: calc(100vh - 10px);
        display: block;
        .pageTitle {
            margin: 0 0 10px;
            font-size: 20px;
            color: $medium-gray;
            opacity: 0.5;
            font-weight: 400;
            display: flex;
            align-items: center;
            grid-gap: 10px;
        }
        .contentSection {
            display: flex;
            background: $silver-color;
            padding: 15px;
            height: calc(100% - 80px);
            border-radius: 5px;
            position: sticky;
            top: 0;
            overflow: auto;
            app-facility-tree {
                background-color: $primary-color;
                p-scrollpanel {
                    width: 100%;
                }
            }
            .p-datatable-wrapper {
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.020);
            }
        }
        app-campus {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
            grid-gap: 20px;
            overflow: hidden;
            app-map-view {
                display: flex;
                width: 100%;
                position: relative;
                border: 2px solid $light-blue-color-1;
                .map-container {
                    width: 100%;
                    height: 100%;
                    background-color: $light-blue-color-2;
                    .leaflet-control {
                        cursor: pointer;
                        &.leaflet-control-attribution {
                            display: none;
                        }
                        &:hover {
                            background-color: $light-gray-4 !important;
                        }
                        &.enableGateway {
                            // background-color: rgba(0, 0, 0, 0.13);
                            background-color: transparent !important;
                            border-radius: 3px;
                            padding: 0px !important;
                            p {
                                margin: 0;
                                background-color: $light-blue-color-3;
                                border-radius: 3px;
                                color: $medium-gray;
                                font-size: 13px;
                                letter-spacing: 0.5px;
                                font-weight: 600;
                                padding: 5px 10px;
                                display: flex;
                                align-items: center;
                                grid-gap: 10px;
                            }
                            &:hover {
                                // background-color: rgba(0, 0, 0, 0.13) !important;
                            }
                        }
                    }
                    .leaflet-top,
                    .leaflet-bottom {
                        &.leaflet-right {
                            .leaflet-bar {
                                padding: 0;
                                border-radius: 50px;
                                height: 40px;
                                width: 40px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                transition: all 0.5s ease;
                                -webkit-transition: all 0.5s ease;
                                &:hover,
                                &:focus {
                                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                                    transform: scale(1.1);
                                    -webkit-transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    app-facility-tree {
        // border-radius: 10px;
        padding: 10px;
        display: flex;
        width: 100%;
        height: 100%;
        // overflow: auto;
        // min-height: calc(100vh - 220px);
    }
}

// nodata
.nodataFound {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $light1-gray;
    font-size: 18px;
    width: 100%;
}

// map menu
.facilityMenu {
    .p-tree {
        padding: 0;
        p-treenode {
            li {
                &:focus {
                    .p-treenode-content {
                        box-shadow: none;
                    }
                }
                .p-treenode-content {
                    flex-direction: row-reverse;
                    padding: 0;
                    color: $secondary-color;
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 500;
                    position: relative;
                    padding-bottom: 15px;
                    padding-left: 25px;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 28px;
                        border-left: 3px solid #E2E8F0;
                        left: 6px;
                        z-index: 99;
                        height: 15px;
                        width: 2px;
                    }
                    .ui-treenode-icon {
                        position: absolute;
                        left: 0;
                        top: 6px;
                    }
                    button {
                        margin: 0;
                    }
                    .p-treenode-label {
                        flex: 1;
                        line-height: 20px;
                    }
                    &.p-highlight {
                        background-color: transparent !important;
                        color: $maroon-color !important;
                        .p-tree-toggler {
                            color: $maroon-color !important;
                        }
                    }
                }
            }
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        li {
            display: flex;
            grid-gap: 10px;
            flex-direction: column;
            line-height: 24px;
            font-weight: 500;
            width: 100%;
        }
    }
}

.p-tree-wrapper {
    ul {
        li {
            margin-bottom: 0px;
            button {
                cursor: pointer;
            }
            ul {
                li {
                    padding-left: 15px;
                    .p-treenode-label {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}

// device menu
app-devices-menu {
    position: absolute;
    right: 15px;
    top: 24px;
    z-index: 9991;
    .deviceList {
        background-color: $primary-color;
        position: absolute;
        right: 0px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px;
        min-width: 236px;
        top: 30px;
        li {
            display: flex;
            grid-gap: 10px;
            align-items: center;
            padding-bottom: 20px;
            &:last-child {
                padding-bottom: 0px;
            }
            h3 {
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                flex: 1;
            }
        }
    }
}

.leaflet-control-fullscreen {
    .leaflet-control-fullscreen-button {
        position: relative;
        font-family: primeicons;
        font-style: normal;
        font-weight: 400;
        &:before {
            content: "\ea0a";
        }
    }
}

.campusSection {
    .p-scrollpanel {
        max-height: calc(100vh - 130px);
        overflow: auto;
    }
}


/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}


/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $light-gray-3;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: $primary-color;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: $dark-blue-color;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}


/* Tooltip text */

.tooltip-text {
    visibility: hidden;
    width: 160px;
    background-color: $medium-gray;
    color: $primary-color;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    right: 70px;
    opacity: 0;
    top: 5px;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}


/* Tooltip arrow */

.tooltip-text::after {
    content: "";
    position: absolute;
    top: 8px;
    /* Bottom of the tooltip */
    right: -10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent $medium-gray;
}