app-google-map {
    width: 100%;
    display: flex;
    .mapWrapper {
        width: 100%;
        google-map {
            .map-container {
                width: 100% !important;
                height: 100% !important;
                background-color: $light-blue-color-1;
            }
        }
    }
}

// map control
.leaflet-bar {
    box-shadow: none;
    border-radius: 4px;
}

.hideGroupView {
    top: 99px;
    position: relative;
    left: -225px;
}

.hideTreeView,
.resetMap,
.hideTableView,
.hideGroupView {
    background-color: $primary-color;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        background-color: $light-gray-1;
    }
    i {
        font-size: 16px;
    }
}

.resetMap {
    margin-bottom: 4px;
    position: absolute;
    left: -66px;
    bottom: 148px;
    border-radius: 2px;
    width: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.hideTreeView {
    margin: 10px 0 0 10px;
    margin: 10px 0 0 10px;
    position: relative;
    left: -190px;
    top: 50px;
}

.hideTableView {
    position: relative;
    bottom: 10px;
}

button {
    &.gm-control-active {
        &.gm-fullscreen-control {
            right: auto !important;
            bottom: 179px;
            top: auto !important;
        }
    }
}

.gmnoprint {
    &.gm-bundled-control {
        &.gm-bundled-control-on-bottom {
            right: auto !important;
            bottom: 140px !important;
            button {
                ~ {
                    .gmnoprint {
                        top: 42px !important;
                    }
                }
            }
        }
    }
}

// marker map css
.leaflet-control-fullscreen,
.leaflet-control-zoom {
    &.leaflet-bar {
        &.leaflet-control {
            margin-bottom: -2px;
            a {
                width: 38px !important;
                height: 38px !important;
                line-height: 38px !important;
            }
        }
    }
}

.leaflet-control-zoom {
    margin-bottom: 10px !important;
}

.treeControl {
    border-radius: 50px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    &:hover,
    &:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
}

// custom dropdown
.app-device-list-filter {
    height: 100%;
    display: inline-block;
}

app-device-penal {
    display: block;
    max-height: calc(100vh - 353px);
    overflow: auto;
}

.deviceListing {
    .deviceListRow {
        padding: 10px 15px;
        border-bottom: 1px solid $light-gray-3;
        // &.highlightDeviceItems {
        //     background-color: $hilight-color;
        // }
        h2 {
            margin: 0;
            font-size: 16px;
        }
        p {
            margin: 5px 0;
            font-size: 14px;
        }
        .switchContainer {
            margin: 5px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            grid-gap: 10px;
            .batteryIcon {
                display: flex;
                align-items: center;
                grid-gap: 5px;
            }
            .location {
                background-color: #67c23a;
                color: #fff;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                i {
                    font-size: 12px;
                }
            }
        }
    }
}

.switch {
    width: 30px;
    min-width: 30px;
    height: 16px;
    &.selectAll {
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .slider {
        &:before {
            height: 12px;
            width: 12px;
            left: 3px;
            bottom: 2px;
        }
    }
    input {
        &:checked {
            + {
                .slider {
                    &:before {
                        transform: translate(13px);
                    }
                }
            }
        }
    }
}